.products {
    width: 100%;
    background: #FFF;
}

.products_container {
    padding: 50px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.products_container h3 {
    font-size: 40px;
    font-weight: 500;
    color: #4c4c4c;
}

.products_container h3 span {
    color: #E95B5A;
}

.products_tap {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
    margin-top: 80px;
    position: relative;
}

.products_tap .tapSpan {
    font-size: 18px;
    cursor: pointer;
    position: relative;
    transition: 200ms;

}

.products_tap .tapSpan:hover {
    color: #E95B5A;
}

.products_tap .tapSpan_active {
    font-size: 18px;
    cursor: pointer;
    position: relative;
    color: #E95B5A;

}

.products_tap::after {
    content: "";
    width: 60%;
    height: 1px;
    background: #a1a1a1;
    position: absolute;
    bottom: -10px;
    transition: 400ms;
    /* left: 1px; */
}

.products_tap .tapSpan_active::after {
    content: "";
    width: 100%;
    height: 4px;
    left: 0;
    background: #E95B5A;
    position: absolute;
    bottom: -16px;
    z-index: 1000;
    border-radius: 10px;

}

.products_section {
    display: grid;
    margin-top: 40px;
    grid-template-columns: repeat(5, 1fr);
    /* Adjust minmax values as needed */
    gap: 20px;
    /* Adjust gap between grid items as needed */
}

.product {
    /* margin-top: 10px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: #F7E5D9;
    border-radius: 16px;
}

.product img {
    width: 160px;
}

.product p {
    font-size: 16px;
    color: #E95B5A;
}

.products_section_hide {
    display: none;
}

@media screen and (max-width: 1500px) {

    .products_container {
        padding: 30px 40px;
    }

    .products_container h3 {
        font-size: 30px;
    }

    .products_tap {
        width: 100%;
        gap: 50px;
        margin-top: 50px;
    }

    .products_tap .tapSpan {
        font-size: 14px;
        cursor: pointer;
        position: relative;
        transition: 200ms;

    }

    .products_tap .tapSpan_active {
        font-size: 16px;
    }

    .products_tap::after {
        width: 100%;
    }

    .products_section {
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
    }

    .product {
        /* margin-top: 10px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        background: #F7E5D9;
        border-radius: 16px;
    }

    .product img {
        width: 160px;
    }

    .product p {
        font-size: 16px;
        color: #E95B5A;
    }

    .products_section_hide {
        display: none;
    }
}

@media screen and (max-width: 720px) {
    .products_section {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
}