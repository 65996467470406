::-webkit-scrollbar{
    width: .8rem;
    height: 0.9rem;
    cursor: pointer;
  }
  
  ::-webkit-scrollbar-track{
    background: #FFF0E9;
    /* border-radius: 100vw;
    margin-block: .5rem; */
    cursor: pointer;
  }
  
  ::-webkit-scrollbar-thumb{
    background: linear-gradient( to bottom, #f47d7d,  #E95B5A);
    border-radius: 100vw;
    cursor: pointer;
  }
  
  ::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(to bottom, #E95B5A,  #f47d7d);
  }