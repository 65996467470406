.location {
    background: #FFF;
}

.location_container {
    padding: 50px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.location_container h3 {
    font-size: 40px;
    font-weight: 500;
    color: #E95B5A;
}

.location_sections {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 50px;
}

.location_section {
    display: flex;
    padding: 20px;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    background-color: #FFF0E9;
    border-radius: 16px;
}

.sec_city {
    display: flex;
    gap: 5px;
    align-items: start;
}

.sec_city h4 {
    font-size: 18px;
    font-weight: 500;
    color: #E95B5A;
}

.sec_city .locIco {
    color: #E95B5A;
}

@media screen and (max-width: 1500px) {

    .location_container {
        padding: 40px 20px;
    }

    .location_container h3 {
        font-size: 30px;
    }

    .location_sections {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        margin-top: 30px;
    }

    .sec_city h4 {
        font-size: 16px;
        font-weight: 500;
        color: #E95B5A;
    }
}